<template>
  <vx-card :title="title">
    <vs-tabs :value="activeTab">
      <vs-tab label="Waiting For Execute">
        <Waiting></Waiting>
      </vs-tab>
      <vs-tab label="Executed">
        <Executed></Executed>
      </vs-tab>
      <vs-tab label="Cancel Execute">
        <Canceled></Canceled>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>

import Waiting from "./_tab/WaitingForExecute.vue";
import Executed from "./_tab/Executed.vue";
import Canceled from "./_tab/Canceled.vue";

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Waiting,
    Executed,
    Canceled,
  },
  data() {
    return {
      title: "Stock Adjustment Form Execute",
      activeTab : this.$route.query.tab ? this.$route.query.tab : 0
    };
  },
};
</script>
